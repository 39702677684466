import React, {useEffect, useState} from 'react'
import { useForm, SubmitHandler } from "react-hook-form";
import InputMask from "react-input-mask";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import {useModal} from "../../context";
import Info from "./Info";

function Order({objectsPres, objectsPresNumb, orderTitles, cover, groups, projects, projectsData}){
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { show, hide} = useModal();
    const schemaReg = yup.object({
        name: yup.string().required('Обязательно к заполнению'),
        email: yup.string().email('Некорректный E-mail').nullable(),
        phone:
            yup.string()
                .test("len", 'Некорректный телефон', (val) => {
                    const val_length_without_dashes = val.replace('+', "").replace(/-|_| /g, "").length;
                    return val_length_without_dashes === 11;
                }),
        message: yup.string().required('Обязательно к заполнению'),
        agree1: yup.boolean().oneOf([true], 'Необходимо согласие'),
    }).required();

    const {
        register,
        setValue,
        setError,
        reset,
        formState: { errors },
        handleSubmit
    } = useForm({
        resolver: yupResolver(schemaReg)
    });

    useEffect(() => {
        let _lbl = ' в проектах ';
        if(projects.split(',').length < 2){
            _lbl = ' в проекте ';
        }
        setValue('message', 'Добрый день, меня заинтересовали квартиры ' + orderTitles)
    }, []);

    const onSubmit = async (data) => {
        setIsSubmitting(true);

        let url = process.env.REACT_APP_API_PROXY + 'wp/v2/generatePDF';
        const formData = new FormData();
        if(cover){
            formData.append("cover", 1);
            formData.append("groups", groups);
        }else{
            formData.append("ids", objectsPres.join(','));
        }

        formData.append("email", data.email.toLowerCase());
        formData.append("name", data.name);
        formData.append("phone", data.phone);
        formData.append("message", data.message);
        formData.append("projects", projects);
        formData.append("projectsData", JSON.stringify(projectsData));

        await fetch(url, {
            method: "POST",
            body: formData,
        }).then((response) => {
            if(response.ok){
                return response.json().then((data) => {
                    show(<Info text="Ваша заявка <br/>успешно отправлена." close={hide}/>, "modal--info");
                    setIsSubmitting(false);
                });
            }else{
                setIsSubmitting(false);
            }
        }).catch((error) => {
            setIsSubmitting(false);
        });
    };

    return (
        <>
            <div className="modal__title text-center">
                Оставить заявку
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className={'login__form' + (isSubmitting ? ' form--loading' : '')}>
                <div className="form__it">
                    <div className={'form__field' + (errors.name ? ' has-error' : '')}>
                        <input
                            type="text"
                            placeholder="Ваше ФИО*"
                            {...register("name")}
                        />
                    </div>
                    {errors.name && (
                        <p className="error__message">
                            {errors.name?.message}
                        </p>
                    )}
                </div>
                <div className="form__it">
                    <div className={'form__field' + (errors.phone ? ' has-error' : '')}>
                        <InputMask
                            autoComplete="nope"
                            type="text"
                            id="phone"
                            placeholder="Телефон*"
                            mask="+7 999-999-99-99"
                            alwaysShowMask={false}
                            {...register("phone")}
                        />
                    </div>
                    {errors.phone && (
                        <p className="error__message">
                            {errors.phone?.message}
                        </p>
                    )}
                </div>
                <div className="form__it">
                    <div className={'form__field' + (errors.email ? ' has-error' : '')}>
                        <input
                            type="text"
                            placeholder="Email*"
                            {...register("email")}
                        />
                    </div>
                    {errors.email && (
                        <p className="error__message">
                            {errors.email?.message}
                        </p>
                    )}
                </div>
                <div className="form__it">
                    <div className={'form__field' + (errors.message ? ' has-error' : '')}>
                        <textarea
                            placeholder="Комментарий*"
                            {...register("message")}
                        />
                    </div>
                    {errors.message && (
                        <p className="error__message">
                            {errors.message?.message}
                        </p>
                    )}
                </div>
                <div className="form__it form__it--indent">
                    <div className={'checkbox__wrap' + (errors.agree1 ? ' has-error' : '')}>
                        <label className="d-flex align-items-center">
                            <input type="checkbox" defaultChecked={true} {...register("agree1")}/>
                            <span className="checkbox__decor"></span>
                            <span className="checkbox__title">
                                Я согласен на обработку персональных данных
                            </span>
                        </label>
                        {errors.agree1 && (
                            <p className="error__message">
                                {errors.agree1?.message}
                            </p>
                        )}
                    </div>
                </div>

                <div className="form__it form__it--actions">
                    <button type="submit" className="btn btn--full btn--white tbn--small d-flex align-items-center justify-content-center">
                        Отправить
                    </button>
                </div>
            </form>
        </>
    );
}

export default Order;
